import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [fullyear, setFullyear] = useState();

  useEffect(() => {
    const year = new Date();
    setFullyear(year);
  }, []);

  return (
    <>
      <footer>
        <div className="r-footer">
          <div className="container">
            <div className="row clearfix">
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="r-footer-block">
                  {/* <img
                    src="assets/images/footer-logo.png"
                    className="d-block img-fluid"
                    alt
                  /> */}
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="r-footer-block">
                  <div className="r-footer-widget r-footer-nav">
                    <h6>USEFUL LINK</h6>
                    <nav>
                      <ul>
                        <li>
                          <Link to="about">About Us</Link>
                        </li>
                        <li>
                          <Link to="privatepolicy">Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to="refundpolicy">Refund Policy</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="r-footer-block">
                  <div className="r-footer-widget r-footer-nav">
                    <h6>OUR INFO</h6>
                    <nav>
                      <ul>
                        <li>
                          <Link to="/contact">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/termconditions">
                            Term &amp; Conditions
                          </Link>
                        </li>
                        <li>
                          <Link to="/faq">Faq</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix r-footer-strip">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                © 2023 Destene. All rights reserved. Design by
                <a
                  href="https://www.dexterous.in/"
                  target="_blank"
                  style={{ marginLeft: 5, color: "#333" }}
                >
                  Dexterous Technologies
                </a>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
