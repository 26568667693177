import React from "react";

const About = () => {
  return (
    <>
      <section id="r-about-info">
        <div className="r-about-info">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="r-about-info-img">
                  <img
                    src="assets/images/about-img-01.png"
                    className="img-fluid d-block m-auto"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="r-about-info-content">
                  <div className="r-sec-head r-sec-head-left r-sec-head-line r-sec-head-w pt-0">
                    <h2>India's favourite taxi services </h2>
                  </div>

                  <ul class="mb-0 pl-0">
                    <p class="fa fa-check-circle">  India's no.1 taxi service in less prices with most comfortable services</p>
                    <p class="fa fa-check-circle">   we are on a mission to save a time of Indian people.with in 2 minutes.we book the taxi to the customers.</p>
                    <p class="fa fa-check-circle"> we are providing a friendly budject on a every ride. </p>
                    <p class="fa fa-check-circle"> we are providing a safety and hygiene precautions to a customers. </p>
                    <p class="fa fa-check-circle"> DESTENE provides the commission less rides with express fast rides.  </p>
                    <p class="fa fa-check-circle"> we are providing a user friendly application.  </p>


                  </ul>
                  {/* <p>
                    Destene is India’s largest mobility platform and one of the
                    world’s largest ride-hailing companies, serving 250+ cities
                    across India, The Destene app offers mobility solutions by
                    connecting customers to drivers and a wide range of vehicles
                    across bikes, auto-rickshaws, metered taxis, and cabs,
                    enabling convenience and transparency for hundreds of
                    millions of consumers and over 1.5 million driver-partners.
                  </p>
                  <p>
                    Destene’s core mobility offering in India is supplemented by
                    its electric-vehicle arm, Destene Electric; India’s largest
                    fleet management business, Destene Fleet Technologies and
                    Destene Skilling, that aims to enable millions of livelihood
                    opportunities for India's youth. With its acquisition of
                    Ridlr, India’s leading public transportation app and
                    investment in Vogo, a dockless scooter sharing solution,
                    Destene is looking to build mobility for the next billion
                    Indians. Destene also extends its consumer offerings like
                    micro-insurance and credit led payments through Destene
                    Financial Services and a range of owned food brands through
                    India’s largest network of kitchens under its Food business.
                  </p>  */}
                </div>
              </div>
            </div>
          </div>

          <section id="r-about-info">
            <div className="r-about-info">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="r-about-info-content">
                      <ul class="mb-0 pl-0">
                        <p class="fa fa-check-circle"> we are ready to solve a all problems of a  customers and riders. </p>
                        <p class="fa fa-check-circle">  DESTENE is the first app.do not charge any middleman commission with the both customers and riders. </p>
                        <p class="fa fa-check-circle"> we are giving a employement to the women's Rider's . with special earnings.   </p>
                        <p class="fa fa-check-circle">  just click the button rider comes near by you.   </p>
                        <p class="fa fa-check-circle">  secure/safer riders verified drivers and like tracking ride and. Commission less rides.   </p>
                        <p class="fa fa-check-circle"> travel any where at any time.   </p>
                        <p class="fa fa-check-circle">  we are mainly concentrating on a women safety in India.   </p>
                        <p class="fa fa-check-circle">   love to travel with a DESTENE. </p>
                        <p className="container">_ why are you too thinking. DESTENE is ready to solve your all problems.why late start using DESTENE___</p>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div className="r-about-info-img">
                      <img
                        src="assets/images/car-slider-img-1.jpg"
                        className="img-fluid d-block m-auto"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

      </section>




    </>
  );
};

export default About;
