import React from 'react'

const Customers = () => {
    return (
        <>
            <div className="r-counter-section r-counter-with-bg m-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="r-counter-box">
                                <div className="r-counter-icon">
                                    <img src="assets/images/icon-happy-customer-white.png" alt="" className="img-fluid" />
                                </div>
                                <div className="r-counts" data-count={172700}>
                                    {/* 1.172.700 */}
                                    <span className="r-count">0</span>
                                </div>
                                <span className="r-count-title"> HAPPY CUSTOMERS </span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="r-counter-box">
                                <div className="r-counter-icon">
                                    <img src="assets/images/icon-cars-count-white.png" alt="" className="img-fluid" />
                                </div>
                                <div className="r-counts" data-count={2450}>
                                    {/* 2.450 */}
                                    <span className="r-count">0</span>
                                </div>
                                <span className="r-count-title"> CARS IN GARAGE </span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="r-counter-box">
                                <div className="r-counter-icon">
                                    <img src="assets/images/icon-total-km-white.png" alt="" className="img-fluid" />
                                </div>
                                <div className="r-counts" data-count={1211100}>
                                    {/* 1.211.100 */}
                                    <span className="r-count">0</span>
                                </div>
                                <span className="r-count-title"> TOTAL KILOMETER/MIL </span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="r-counter-box">
                                <div className="r-counter-icon">
                                    <img src="assets/images/icon-car-center-white.png" alt="" className="img-fluid" />
                                </div>
                                <div className="r-counts" data-count={47250}>
                                    {/* 47.250 */}
                                    <span className="r-count">0</span>
                                </div>
                                <span className="r-count-title"> CAR CENTER SOLUTIONS </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Customers