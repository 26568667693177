import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";

const Hadear = () => {
  const [ismobile, setIsmobile] = useState(0);

  // Get the scroll position

 
 useEffect(() => {
  let header = document.getElementById('site-header-inner');

  document.addEventListener('scroll', function() {
    
    let scrollPos = window.pageYOffset;
    
    if ( scrollPos > 100 ) {
      header.style.backgroundColor = "black";
    } else {
      header.style.backgroundColor = "transparent";
    }
  });
 }, [])

  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top" id="site-header-inner">
        <div className="container">
          <Link className="navbar-brand">
            <div className="logo-wrapper">
              <Link
                className="d-flex justify-content-center align-items-center"
                to="/"
              >
                {/* <img src="assets/images/logo.png" className="img-fluid" alt="Edusquad" /> */}
              </Link>
            </div>
          </Link>
          <div 
            className={ismobile ? "moblink" : "desklink"}
            onClick={() => setIsmobile(false)}
          >
            <ul >
              <Link className="text-decoration-none" to="/" >
                <li >HOME</li>
              </Link>
              <Link className=" text-decoration-none" to="/about">
                <li className="li">ABOUT</li>
              </Link>
              <Link className=" text-decoration-none" to="/faq">
                <li className="li">FAQ</li>
              </Link>

              <Link className=" text-decoration-none" to="/contact">
                <li>CONTACT</li>
              </Link>
            </ul>
          </div>

          <button className="vavbutn" onClick={() => setIsmobile(!ismobile)}>
            {ismobile ? <IoMdClose /> : <GiHamburgerMenu />}
          </button>
        </div>
      </nav>
    </>
  );
};
export default Hadear;
