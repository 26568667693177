import React from 'react'

const NewMember = () => {
    return (
        <>
            <section id="r-new-member">
                <div className="r-new-member">
                    <div className="container">
                        <div className="r-sec-head r-sec-head-left r-sec-head-line r-sec-head-r pt-0">
                            <span>NEW MEMBER DISCOUNT</span>
                            <h2>Reserved Now &amp; Get <b>50% Off</b> <br />for Audi &amp; Mercedes Only.</h2>
                        </div>
                        <div className="row r-discounted-car">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="r-discount-single">
                                    <a href="#"><img src="assets/images/discount-car-01.jpg" alt="" /></a>
                                    <div className="r-discount-content">
                                        <ul className="mb-0 pl-0 r-starts">
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                        </ul>
                                        <h4><a href="#">Audi Cabriolet R8</a></h4>
                                        <div className="r-price-discount"><span className="r-cut-price">₹50.00</span> <b>25.00 USD</b> per day</div>
                                        <ul className="pl-0 mb-0 r-car-point clearfix">
                                            <li><i className="fa fa-cogs" /><span>MANUAL</span></li>
                                            <li><i className="fa fa-beer" /><span>PETROL</span></li>
                                            <li><i className="fa fa-road" /><span>2.3k CC</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="r-discount-single">
                                    <a href="#"><img src="assets/images/discount-car-02.jpg" alt="" /></a>
                                    <div className="r-discount-content">
                                        <ul className="mb-0 pl-0 r-starts">
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                        </ul>
                                        <h4><a href="#">Mercedes Benz I7</a></h4>
                                        <div className="r-price-discount"><span className="r-cut-price">₹50.00</span> <b>25.00 USD</b> per day</div>
                                        <ul className="pl-0 mb-0 r-car-point clearfix">
                                            <li><i className="fa fa-cogs" /><span>MANUAL</span></li>
                                            <li><i className="fa fa-beer" /><span>PETROL</span></li>
                                            <li><i className="fa fa-road" /><span>2.3k CC</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="r-discount-single">
                                    <a href="#"><img src="assets/images/discount-car-03.jpg" alt="" /></a>
                                    <div className="r-discount-content">
                                        <ul className="mb-0 pl-0 r-starts">
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                            <li><i className="fa fa-star" /></li>
                                        </ul>
                                        <h4><a href="#">Audi Cabriolet R8</a></h4>
                                        <div className="r-price-discount"><span className="r-cut-price">₹50.00</span> <b>25.00 USD</b> per day</div>
                                        <ul className="pl-0 mb-0 r-car-point clearfix">
                                            <li><i className="fa fa-cogs" /><span>MANUAL</span></li>
                                            <li><i className="fa fa-beer" /><span>PETROL</span></li>
                                            <li><i className="fa fa-road" /><span>2.3k CC</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default NewMember