import React from 'react'

const FeturedCar = () => {
    return (
        <>

            <section id="r-drivers">
                <div className="r-driver-blog-dark">
                    <div className="r-sub-banners clearfix">
                        <div className="r-sub-banner-in r-sub-dark">
                            <span>BLACK CARS DISCOUNT 50%</span>
                            <h4>Special Offers For <br /><b>Black Friday.</b></h4>
                        </div>
                        <div className="r-sub-banner-in r-sub-accent">
                            <span>MONTHLY SPECIAL OFFER</span>
                            <h4>Rent 3 Days &amp; Get <br /><b>Free Insurance.</b></h4>
                        </div>
                    </div>
                    <div className="r-sec-head r-accent-color r-sec-head-s">
                        <span>OUR PROFESSIONAL STAFF</span>
                        <h2>Destene <b>Drivers.</b></h2>
                    </div>
                    <div className="container">
                        <div className="r-driver-blog">
                            <div className="row clearfix">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                    <div className="r-drivers">
                                        <div className="r-drivers-detail">
                                            <div className="name">Luis <br />Henrique</div>
                                            <div className="icon">
                                                <i className="fa fa-facebook" aria-hidden="true" />
                                                <i className="fa fa-twitter" aria-hidden="true" />
                                                <i className="fa fa-instagram" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="drivers-img">
                                            <img src="assets/images/driver-blog-img.jpg" className="img-fluid d-block m-auto" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                    <div className="r-drivers">
                                        <div className="r-drivers-detail">
                                            <div className="name">Robertho Garcia</div>
                                            <div className="icon">
                                                <i className="fa fa-facebook" aria-hidden="true" />
                                                <i className="fa fa-twitter" aria-hidden="true" />
                                                <i className="fa fa-instagram" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="drivers-img">
                                            <img src="assets/images/driver-blog-img-2.jpg" className="img-fluid d-block m-auto" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                    <div className="r-drivers">
                                        <div className="r-drivers-detail">
                                            <div className="name">Daniel Quaresma</div>
                                            <div className="icon">
                                                <i className="fa fa-facebook" aria-hidden="true" />
                                                <i className="fa fa-twitter" aria-hidden="true" />
                                                <i className="fa fa-instagram" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="drivers-img">
                                            <img src="assets/images/driver-blog-img-3.jpg" className="img-fluid d-block m-auto" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="r-all-but text-center pt-5">
                            <a href="#" className="btn btn-full">View All Drivers</a>
                        </div>
                    </div>
                </div>
            </section>





        </>
    )
}

export default FeturedCar