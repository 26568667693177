import React from "react";

const Contact = () => {
  return (
    <>
      <section id="r-get-in-touch">
        <div className="r-get-in-touch">
          <div className="container">
            <div className="r-get-header">
              <br></br>
              <br></br>
              <h2>
                Keep In Touch<b> With Us.</b>
              </h2>

            </div>
            <div className="r-get-form">
              <div className="clearfix">
                <div className="form-group">
                  <input
                    required
                    name="userName"
                    id="userName"
                    type="text"
                    placeholder="User name"
                  />
                </div>
                <div className="form-group">
                  <input
                    required
                    name="userEmail"
                    id="userEmail"
                    type="email"
                    placeholder="Email Address"
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  required
                  name="subject"
                  id="subject"
                  placeholder="Title Message"
                />
              </div>
              <div className="form-group">
                <textarea
                  placeholder="Message"
                  name="content"
                  required
                  id="content"
                  defaultValue={""}
                />
              </div>
              <div className="text-center">
                <button onclick="sendContact();" className="btn btn-full">
                  SEND MESSAGE NOW
                </button>
              </div>
              <div
                className="mt-4 text-uppercase text-white text-center"
                id="mail-status"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
