import React from 'react'

const Testimonial = () => {
    return (
        <div>

            <section id="r-quote">
                <div className="r-quote r-quote-sub pt-0 r-quote-noafter">
                    <div className="r-sec-head r-accent-color r-sec-head-t">
                        <span>LATEST TESTIMONIAL</span>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12" data-wow-delay="0.2s">
                                <div id="r-quote-carousel" className="carousel slide">
                                    <div className="carousel-inner" role="listbox">
                                        <div className="carousel-item">
                                            <p>“This was our first time renting from Destene Cars and we were very pleased with the whole
                                                experience. Your price was lower than other companies. Our rental experience was good from start
                                                to finish, so we’ll be back in the future lorem ipsum diamet.”</p>
                                        </div>
                                        <div className="carousel-item active">
                                            <p>“This was our first time renting from Destene Cars and we were very pleased with the whole
                                                experience. Your price was lower than other companies. Our rental experience was good from start
                                                to finish, so we’ll be back in the future lorem ipsum diamet.”</p>
                                        </div>
                                        <div className="carousel-item">
                                            <p>“This was our first time renting from Destene Cars and we were very pleased with the whole
                                                experience. Your price was lower than other companies. Our rental experience was good from start
                                                to finish, so we’ll be back in the future lorem ipsum diamet.”</p>
                                        </div>
                                    </div>
                                    <ol className="carousel-indicators">
                                        <li data-target="#r-quote-carousel" data-slide-to={0}>
                                            <img className="img-fluid d-block" src="assets/images/user-02.jpg" alt="" />
                                            <span>
                                                <b>Robertho Garcia</b><br />
                                                Graphic Designer
                                            </span>
                                        </li>
                                        <li data-target="#r-quote-carousel" data-slide-to={1} className="active text-center">
                                            <img className="img-fluid d-block" src="assets/images/user-01.png" alt="" />
                                            <span>
                                                <b>Robertho Garcia</b> <br />
                                                Graphic Designer
                                            </span>
                                        </li>
                                        <li data-target="#r-quote-carousel" data-slide-to={2}>
                                            <img className="img-fluid d-block" src="assets/images/user-03.jpg" alt="" />
                                            <span>
                                                <b>Robertho Garcia</b> <br />
                                                Graphic Designer
                                            </span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Testimonial