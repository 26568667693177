import React from "react";

const Faq = () => {
  return (
    <>
      <section className="container " style={{ fontFamily: "Calibri" }}>
        <h1 className=" text-center mt-lg-5">

          <br></br>
          <br></br>
          <b>FAQ</b>
        </h1>
        <h4>What is Destene Rental Service?</h4>
        <p className="mt-lg-5">
          Destene Rental is a brand new offering from Destene. DesteneRentals has
          selected a few of the trusted Bike Vendor Partners in select
          cities/locations who provide available bikes for rent. Based on your
          date and location, bikes available will be shown to you. Reasonable
          efforts are made by Destene Rental to show you the latest tariff of the
          bikes being shown.
        </p>
        <h4>What is Destene Rentals Protect?</h4>
        <p className="mt-lg-3">
          Destene Rentals Protect is our new program that covers all rides booked
          on Destene.rentals website. As part of Destene Rentals Protect we offer
          the following benefits.
        </p>
        <ul>
          <li>Upto 3k OPD claim in the event of an accident.</li>
          <li>
            Rs 3 Lakh claim in the event of permanent disability to the Hirer.
          </li>
          <li>Rs 5 lakh claim in the event of Death of the Hirer.</li>
        </ul>

        <h4>What is the min Rental Duration for which I can book a bike?</h4>
        <p className="mt-lg-3">
          The min rental duration that can be selected on Destene Rental Platform
          is 24 hrs. You can book a bike upto 2 months on the Destene Rental
          site. In Fact the longer the duration, the more discount you get. The
          discount is auto calculated and after discount pricing is shown.
        </p>

        <h4>Is booking cancellation allowed?</h4>
        <p className="mt-lg-3">The booking can be cancelled by the user from the 'My Bookings' section before the booking is accepted by the Vendor Partner and 100% payment will be refunded to the user.</p>
        <p className="mt-lg-3">However, in some cases a penalty or cancellation fee may be charged as per the cancellation policies. Specific cancellation charges as policy as below.</p>


        <ul>
          <li>Customer side cancellations and rejections</li>
          <p className="mt-lg-3">1. 100% refund of the rental amount if a ride is Cancelled/Rejected by Destene or Vendor due to unavailability of the bike.</p>
          <p>2. 100% refund if the ride is cancelled by the user before a vendor partner has accepted the booking.</p>
        </ul>
        <ul>
          <li>For Customer side cancellations</li>
          <p className="mt-lg-3">1. 100% refund to the user if the booking is cancelled within 30 Mins of booking made.</p>
          <p>2. Rs 100 is deducted for customer side cancellation for booking cancelled within 30- 90 mins of booking made and the balance rental amount is Refunded.</p>
          <p>3. Rs 250 is deducted for booking cancelled between after 90 mins of booking made and the balance rental amount is Refunded.</p>
        </ul>
      </section>
    </>
  );
};

export default Faq;
