import { useEffect } from 'react';
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import './App.css';
import MainLayOut from './components/layout/MainLayOut';
// import Advantages from './components/layout/home/Advantages';
import Home from './components/layout/home/Home';
import PrivatePolicy from './pages/PrivatePolicy';
import TermConditions from './pages/TermConditions';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import About from './pages/About';
import RefundPolicy from './pages/RefundPolicy';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}



function App() {
  return (
    <HashRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" exact element={<MainLayOut />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/privatepolicy" element={<PrivatePolicy />} />
        <Route path="/termconditions" element={<TermConditions />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
       
      </Route>
    </Routes>
  </HashRouter>
  );
}

export default App;
