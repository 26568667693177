import React from "react";
import Advantages from "./Advantages";
import NewMember from "./NewMember";
import FeturedCar from "./FeturedCar";
import Testimonial from "./Testimonial";
import Customers from "./Customers";
import LatestNews from "./LatestNews";
import HomeSlider from "./HomeSlider";

const Home = () => {
  return (
    <div className="mainContainer">
      {/* <div className="swiperContainer2">
        <div className="swipermain2" style={{position:"relative"}}>
          <div className="r-slider owl-carousel" id="defaultHomeSlider">
            <HomeSlider />
          </div>
          <div className="r-car-search" style={{position:"absolute",bottom:0,left:0,zIndex:22}}>
            <div className="container">
              <div className="r-top-form-title animated fadeInUp">
                <span>120+ CARS TYPE &amp; BRANDS</span>
                <h3>
                  Search Your <b>Best Cars.</b>
                </h3>
              </div>
              <form>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label> Car Brand </label>
                      <select className="form-control">
                        <option>Any Brands</option>
                        <option>Any Brands</option>
                        <option>Any Brands</option>
                        <option>Any Brands</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label> Car Type </label>
                      <select className="form-control">
                        <option>Any Type</option>
                        <option>Any Type</option>
                        <option>Any Type</option>
                        <option>Any Type</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label> Car Price </label>
                      <select className="form-control">
                        <option>Price Low to High</option>
                        <option>Price High to Low</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <input
                        type="submit"
                        className="form-control btn-primary"
                        defaultValue="Search Car Now"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      
            <HomeSlider />


      <Advantages />
      <NewMember />
      <FeturedCar />
      <Testimonial />

      {/* <Frequenly /> */}
      <Customers />
      <LatestNews />
    </div>
  );
};

export default Home;
