import React from "react";

const Advantages = () => {
  return (
    <>
      <section id="r-advantages-part section"  className="r-advantages-part">
        <div className="r-advantage-main-part">
          <div className="container clearfix">
            <div className="advantage-head">
              <span>120+ CARS TYPE &amp; BRANDS</span>
              <h2>
                Destene <b>Advantages.</b>
              </h2>
            </div>
            <div className="row clearfix">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="r-advantages-box">
                  <div className="icon">
                    <img src="assets/images/advantage-icon-1.png" alt="" />{" "}
                  </div>
                  <div className="head animated pulse">
                    24/7 Customer Online Support
                  </div>
                  <div className="sub-text">Call us Anywhere Anytime</div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="r-advantages-box">
                  <div className="icon">
                 
                    <img src="assets/images/advantage-icon-2.png" alt="" />{" "}
                  </div>
                  <div className="head animated pulse">
                    Reservation Anytime You Wants
                  </div>
                  <div className="sub-text">24/7 Online Reservation</div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="r-advantages-box">
                  <div className="icon">
                 
                    <img src="assets/images/advantage-icon-3.png" alt="" />{" "}
                  </div>
                  <div className="head animated pulse">
                    Lots of Picking Locations
                  </div>
                  <div className="sub-text">250+ Locations</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Advantages;
