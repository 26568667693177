import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper";


const HomeSlider = () => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 4500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination]}
      className="mainSlider"
    >
      <SwiperSlide>
        <div className="r-slider-item">
          <img
            src="assets/images/main-slider-01.jpg"
            className="img-fluid d-block m-auto"
            alt=""
          />
          <div className="container">
            <div className="r-slider-top-content">
              <h1 className="animated fadeInDown">
                Kia Rio <span>z</span>
              </h1>
              <h4 className="animated fadeInLeft">
                FOR RENT <strong>₹50</strong> PER DAY
              </h4>
              <a to="" className="btn btn-outlined animated fadeInUp">
                
                Reserve Now
              </a>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="r-slider-item">
          <img
            src="assets/images/main-slider-04.jpg"
            className="img-fluid d-block m-auto"
            alt=""
          />
          <div className="container">
            <div className="r-slider-top-content">
              <h1>
                BMW <span>3</span>
              </h1>
              <h4>
                FOR RENT <strong>₹150</strong> PER DAY
              </h4>
              <a to="" className="btn btn-outlined">
                
                Reserve Now
              </a>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="r-slider-item">
          <img
            src="assets/images/main-slider-05.jpg"
            className="img-fluid d-block m-auto"
            alt=""
          />
          <div className="container">
            <div className="r-slider-top-content">
              <h1>
                Audi <span>A4</span>
              </h1>
              <h4>
                FOR RENT <strong>₹100</strong> PER DAY
              </h4>
              <a to="" className="btn btn-outlined">
                
                Reserve Now
              </a>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default HomeSlider;
