import React from "react";

const RefundPolicy = () => {
  return (
    <>
      <section className="container " style={{ fontFamily: "Calibri" }}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h2 className="c1 mt-lg-5"><b>CANCELLATION POLICY</b></h2>
        <p>1. As per Destene's cancellation policy, Destene shall refund the
          complete Booking Amount to the End User if the Booking is cancelled by
          the End User before 24 hours of the scheduled booking start/ pickup
          date. However, if it's less than 24 hours, then Destene will forfeit
          the Booking Amount and in such case there shall be not refund payable
          to the End User.
        </p>
        <p>2. In the event of cancellation done by Vendor Partner, then the User
          is eligible for complete refund of the Booking Amount unless the cause
          of such cancellation is directly attributable to you including but not
          limited to change in the booking plans by the User etc.
        </p>
      </section>
    </>
  );
};

export default RefundPolicy;
