import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Hadear from './home/Hadear'

const MainLayOut = () => {
  return (
    <>
    <Hadear />
    <Outlet />
    <Footer />
    </>
  )
}

export default MainLayOut