import React from 'react'

const LatestNews = () => {
    return (
        <>

            <section id="r-latest-news">
                <div className="r-latest-news">
                    <div className="r-sec-head r-sec-head-b">
                        <span>ARTICLES FROM BLOG</span>
                        <h2>Our <b>Latest News.</b></h2>
                    </div>
                    <div className="container">
                        <div className="owl-carousel r-latest-news-list" id="r-latest-news-slider">
                            <div className="r-latest-news-single">
                                <a href="#" className="d-inline-block"><img src="assets/images/latest-news-01.jpg" className="img-fluid d-block m-auto" alt="" /></a>
                                <div className="r-latest-news-content">
                                    <span className="r-date">27 JUNE 2018</span>
                                    <h4><a href="#">What To Do if Your Rental Car Has Met With An Accident.</a></h4>
                                    <a href="#" className="r-read-more">READ ARTICLE</a>
                                </div>
                            </div>
                            <div className="r-latest-news-single">
                                <a href="#" className="d-inline-block"><img src="assets/images/latest-news-02.jpg" className="img-fluid d-block m-auto" alt="" /></a>
                                <div className="r-latest-news-content">
                                    <span className="r-date">27 JUNE 2018</span>
                                    <h4><a href="#">What To Do if Your Rental Car Has Met With An Accident.</a></h4>
                                    <a href="#" className="r-read-more">READ ARTICLE</a>
                                </div>
                            </div>
                            <div className="r-latest-news-single">
                                <a href="#" className="d-inline-block"><img src="assets/images/latest-news-03.jpg" className="img-fluid d-block m-auto" alt="" /></a>
                                <div className="r-latest-news-content">
                                    <span className="r-date">27 JUNE 2018</span>
                                    <h4><a href="#">What To Do if Your Rental Car Has Met With An Accident.</a></h4>
                                    <a href="#" className="r-read-more">READ ARTICLE</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="r-follow-us text-center">
                        <img src="assets/images/instagram.png" className="img-fluid d-block m-auto" alt="" />
                        <span>Follow Our Instagram <a href="#">#Destene</a></span>
                    </div>
                </div>
            </section>


        </>
    )
}

export default LatestNews