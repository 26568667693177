import React from 'react'

const TermConditions = () => {
  return (
    <>
      <div className='container' style={{ fontFamily: "'Times New Roman'" }}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h5 className='text-center pt-lg-5'><b>TERMS & CONDITIONS </b></h5>
        <p className='container mt-lg-5'>Destene | Destene Cabs</p>
        <p className='mt-lg-5'> Updated effective from may, 2023 </p>
        <p className='mt-lg-4'>This Site/ Application/ Services is/are operated (“Destene”).</p>
        <p className='mt-lg-5'>These terms and conditions (“User Terms”) apply to Your visit to and use of the Site whether through a computer or a mobile phone, the Service and the Application, as well as to all information, recommendations and or services provided to You on or through the Site, and the Application. This document is an electronic record in terms of Information Technology Act, 2000 and rules thereunder as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
        <p className='mt-lg-4'>By clicking on the “I ACCEPT” button or by using Destene’s services, You are acknowledging and consenting to be bound by these User Terms. PLEASE ENSURE THAT YOU READ AND UNDERSTAND ALL THESE USER TERMS BEFORE YOU USE THE SITE. If You do not wish to accept any of the User Terms, then please do not use the Site or avail any of the services being provided therein. YOUR AGREEMENT TO THESE USER TERMS SHALL OPERATE AS A BINDING AGREEMENT BETWEEN YOU AND Destene IN RESPECT OF THE USE AND SERVICES OF THE SITE. Your access and use of the Services constitutes your agreement to be bound by these Terms, which establishes a contractual relationship between you and Destene. These Terms expressly supersede prior agreements or arrangements with you. Destene may immediately terminate these Terms or any Services with respect to you, or generally cease offering or deny access to the Services or any portion thereof, at any time for any reason. Supplemental terms may apply to certain Services, such as policies for a particular event, activity or promotion, and such supplemental terms will be disclosed to you in connection with the applicable Services. Supplemental terms are in addition to, and shall be deemed a part of, the Terms for the purposes of the applicable Services.</p>

        <p className='mt-lg-3'>IT IS HEREBY EXPRESSLY CLARIFIED THAT THESE USER TERMS WILL NOT APPLY TO CORPORATE ENTITIES WHO ARE GOVERNED BY THE Destene CORPORATE TERMS OF USE. HOWEVER, ALL AUTHORIZED USERS TAKING ‘Destene CORPORATE RIDES’ I.E., FOR CUSTOMERS WHO ARE AVAILING RIDES UNDER THE DIRECT ARRANGEMENTS BETWEEN CORPORATE ENTITIES AND Destene SHALL ALSO BE GOVERNED BY THESE USER TERMS.</p>





        <h5 className='mt-lg-5'><b>1.  DEFINITIONS </b></h5>
        <p className='mt-lg-3'>All of the defined and capitalized terms in these User Terms will have the meaning assigned to them here below:</p>
        <p className='mt-lg-4'>1.  “Account” shall mean the account created by the Customer on the Application for availing the Services provided by Destene.</p>
        <p className='mt-lg-4'>2.   “Additional Fee” shall mean any toll, duty, inter-state taxes, etc. that may have been incurred in providing the Services and payable to any third party / government authorities for undertaking the Ride under Applicable Law.</p>
        <p className='mt-lg-4'>3.   “Applicable Laws” shall mean and include all applicable statutes, enactments, acts of legislature or parliament, laws, ordinances, rules, by-laws, regulations, notifications, guidelines, policies, directions, directives and orders of any governmental authority, tribunal, or a court of India. </p>
        <p className='mt-lg-4'>4.   “Application” shall mean the mobile application “Destene Cabs” updated by Destene from time to time.</p>
        <p className='mt-lg-4'>5.  “Convenience Fee”/” Access Fee” shall mean the fee payable by the Customer to Destene for the Service i.e., for availing value added services including but not limited to technology services, door to door service, customer support and cashless payment options. Convenience Fee may also include any such fee that may be charged by third parties towards providing access to a particular location such as airports, railway stations etc. </p>
        <p className='mt-lg-4'>6.  “Cancellation Fee” shall mean a fee payable by You, towards cancellation of a Ride or a booking as detailed in Clause 7 of these User Terms and the Cancellation policy.</p>


        <h5 className='mt-lg-5'><b> 2.  ELIGIBILITY </b></h5>
        <p className='mt-lg-4'>You will be “Eligible” to use the Services only when You fulfil all of the following conditions:</p>
        <p className='mt-lg-4'>You have attained at least 18 (eighteen) years of age. (ii) You are competent to enter into a contract under the Applicable Laws.</p>

        <h5 className='mt-lg-5'><b> 3.   REGISTRATION AND ACCOUNT </b></h5>
        <p className='mt-lg-4'> 3.1  You understand and acknowledge that You can register on the Site only after complying with the requirements of this Clause 3 and by entering Your Registration Data.</p>
        <p className='mt-lg-4'>3.2  You understand and acknowledge that You can register on the Site only after complying with the requirements of this Clause 3 and by entering Your Registration Data.</p>

        <h5 className='mt-lg-5'><b> 4.   SERVICES </b></h5>
        <p className='mt-lg-4'>The Services constitute a technology platform that enables users of Destene’s mobile applications or websites provided as part of the Services (each, an “Application”) to arrange and schedule transportation and/or logistics services with independent third party providers of such services, including independent third party transportation providers and independent third party logistics providers under agreement with Destene (“Third Party Providers”). YOU ACKNOWLEDGE THAT Destene DOES NOT PROVIDE TRANSPORTATION OR LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION CARRIER AND THAT ALL SUCH TRANSPORTATION OR LOGISTICS SERVICES ARE PROVIDED BY INDEPENDENT THIRD PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY Destene OR ANY OF ITS AFFILIATES.</p>
        <p className='mt-lg-4'> 4.1 The Services allows You to send a request through Destene to a Driver on the Destene network. The Driver has sole and complete discretion to accept or reject each request for Service. If the Driver accepts a request, Destene notifies You and provides information regarding the Driver- including Driver name, Vehicle license number, telephone contact details of the Driver and such other details as Destene may determine.</p>

        <p className='mt-lg-4'>4.2  Destene shall procure reasonable efforts to bring You into contact with a Driver, subject to the availability of Driver in or around Your location at the moment of Your request for such services.</p>
        <p className='mt-lg-4'> 4.3  By using the Application or the Service, You further agree that: (i) You will only use the Service or download the Application for Your sole, personal use and will not resell or assign it to a third party; (ii) You will not use an account that is subject to any rights of a person other than You without appropriate authorization; (iii) You will not use the Service or Site for unlawful purposes; (iv) You will not try to harm the Service, Site or our network in any way whatsoever; (v) You will provide Destene with such information and documents which Destene may reasonably request; (vi) You will only use an authorized network to avail the Service; (vii) You are aware that when requesting Services, whether by message, via Site or calling the call center of Destene, standard messaging charges, data charges, voice charges, as applicable, of the Your and Our phone network service providers, will apply; (viii) You will comply with all Applicable Law from Your country of domicile and residence and the country, state and/or city in which You are present while using the Site or Service; and (ix) You are aware of and shall comply with the Information Technology Act, 2000 and the rules, regulations and guidelines notied thereunder.</p>

        <p className='mt-lg-5'> 4.4 Destene reserves the right to immediately terminate the Service and the use of the Application in the event of non-compliance with any of the above requirements. Further, Destene will store the information provided by You or record your calls for contacting You for all Service related matters. You shall promptly inform Destene on any change in the information provided by You.</p>

        <p className='mt-lg-4'> 4.5 Destene or an authorized representative of Destene, shall provide information regarding services, discounts and promotions provided by Destene or a TPSP to You on the Destene App or by way of an SMS or email to Your registered mobile number/registered email ID. You also have the option to discontinue receiving such information at any point of time. To discontinue receiving such information, You may at any point of time visit the specific link provided in the Site to discontinue the same. </p>




        <h5 className='mt-lg-5'><b> 5.   CONFIRMATION OF BOOKING </b></h5>
        <p className='mt-lg-4'> 5.1  Destene shall, upon receiving the booking request from You in the manner set out above, proceed to confirm or decline the booking based on the availability of Vehicles at the pickup time and location , which shall be informed to You vide the application or SMS or email. In the event the booking is confirmed, You shall check the booking details including but not limited to pick up time and pick up place, and if there is incorrect detail, the same needs to be informed to us immediately by calling our call center.</p>
        <p className='mt-lg-5'> 5.2 Upon confirmation of booking, a One Time Pin (OTP) shall be provided on the Application which shall be further shared by the Customer with the Driver. In the event that the Customer does not provide OTP to the Driver, Driver shall not be responsible for not starting on the services.</p>
        <p className='mt-lg-4'> 5.3  You shall bear the consequences and damages for any delay that may be caused to You due to Your failure to check the confirmation message via the application, SMS or email or failure to inform Destene of the incorrect details immediately.</p>
        <p className='mt-lg-4'> 5.4  Certain selective customers will be eligible to avail the Service under a ‘Guest Booking’ feature, provided by Destene. Such customers will also be bound by the terms and conditions set out herein.</p>


        <h5 className='mt-lg-5'><b> 6.   PAYMENT </b></h5>
        <p className='mt-lg-4'>  Destene shall provide an estimate of the Total Ride Fee to You at the beginning of every Ride. Such an estimate provided by Destene shall be subject to change on account of several factors and shall be different to the actual Total Ride Fee levied at the end of ride. The Customer shall pay the actual Total Ride fee shown at the end of the ride in accordance with terms mentioned herein. </p>



        <h5 className='mt-lg-5'><b> 7.   USER VIDesteneTION OF USER TERMS </b></h5>
        <p className='mt-lg-4'>  You shall not smoke, drink or carry any contraband substances in the Vehicles or misbehave with the Driver or distract the Driver or act in viDestenetion of Applicable Law. In the event You are found to be involved in the activities set out above, You shall be liable to pay a fine to us and we shall also have the right to terminate the Ride. In the event You fail to pay fine after the completion of the Ride, we may at our discretion, take such steps as may be available to us under Applicable Law. You shall also be blacklisted as a result of non-payment of the fine or misbehaving as the case may be, and in such event, Your Account may be terminated by Destene.</p>

        <h5 className='mt-lg-5'><b> 8.   CUSTOMER RELATIONSHIP MANAGEMENT </b></h5>
        <p className='mt-lg-4'> 8.1 All issues, opinions, suggestions, questions and feedback while availing our Services can be communicated to us via several modes such as self serve app or website or email. After completion of the ride, you are entitled to give a suitable rating for the service and ride. You agree to be fair, accurate and non-disparaging while leaving comment, feedbacks, testimonials or reviews on or about the Rides or Services.</p>
        <p className='mt-lg-4'> 8.2  Reporting of any issue needs to be within 30 (thirty) days of the happening of the issue, failing which, such issue will not be addressed.</p>


        <h5 className='mt-lg-5'><b> 9.   EMERGENCY SERVICES TO CUSTOMER </b></h5>
        <p className='mt-lg-4'> 9.1  By accepting these User Terms and on pressing the SOS button on the Destene App, You consent to Destene using its best endeavors to provide You with assistance during a safety incident, emergencies or distress, as per Destene’s internal policies on emergency response. Destene may undertake remedial action(s), including, but not limited to the following:</p>
        <p className='mt-lg-4'> 9.2 When you press the SOS button and report a safety incident, the Destene representative may escalate the incident internally or liaise with any third party security service providers to assist You and/or deploy response teams to Your location. </p>
        <p className='mt-lg-4'> 9.3 Destene may inform any government authorities, such as the concerned police station, about the incident and seek their assistance; Destene may also provide such authority(ies) with your personal information including but not limited to your exact GPS location, your registered email ID and mobile number to enable them to take appropriate steps to assist You. </p>
        <h5 className='mt-lg-5'><b> 10.   FORCE MAJEURE </b></h5>
        <p className='mt-lg-4'> We shall not be liable for any failure to perform any obligations under this User Terms, if the performance is prevented, hindered or delayed by a Force Majeure Event and in such case our obligations under this User Terms shall be suspended for so long as the Force Majeure Event continues.</p>


        <h5 className='mt-lg-4'> <b> 11. INDEMNIFICATION</b></h5>
        <p className='mt-lg-4'>  By accepting these User Terms and using the Service, You agree that You shall defend, indemnify and hold Destene, its affiliates, their licensors, and each of its officers, directors, other users, employees, attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) arising out of or in connection with: (a) Your viDestenetion or breach of any term of these User Terms or any Applicable Law or regulation, whether or not referenced herein; (b) Your viDestenetion of any rights of any third party, including the TPSPs or (c) Your use or misuse of the Application or Service.</p>

        <h5 className='mt-lg-4'> 12. LIABILITY</h5>
        <p className='mt-lg-4'>  The information, recommendations and/or Services provided to You on or through the Site, the Application and Destene call center are for general information purposes only and do not constitute advice. Destene will reasonably keep the Site and its contents correct and up to date but does not guarantee that (the contents of) the Site is free of errors, defects, malware and viruses or that the Site is correct, up to date and accurate.</p>

        <h5 className='mt-lg-4'> 13. APPLICATION LICENSE</h5>
        <p className='mt-lg-4'>  Subject to Your compliance with these User Terms, Destene grants You a limited, revocable, non-exclusive, non-transferable and non-sub-licensable license to download and install a copy of the Application on a single mobile device that You own or control and to run such copy of the Application solely for Your own personal use and to use the Site.</p>

        <h5 className='mt-lg-4'> 14. CONTENT POSTED BY CUSTOMERS</h5>
        <p className='mt-lg-4'> Destene may accept posting of any notes, messages, e-mails, photos, drawings, profiles, opinions, ideas, images, videos, audio files or other materials or information given by you on the Site (“Posted Content”) by You. You represent that You have obtained all permissions and consents required to post the Posted Content and such Posted Content complies with all requirements of the Posted Content. Destene shall not in any manner be responsible for or endorse the Posted Content.</p>

        <h5 className='mt-lg-4'> 15. INTELLECTUAL PROPERTY OWNERSHIP</h5>
        <p className='mt-lg-4'>    Destene alone (and its licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to (i) the Site, Application, product, Service and any suggestions, ideas, enhancement requests, feedback, recommendations or any other offering; (ii) text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code; or (iii) other information provided by You or any other party relating to the Site, Application or the Service. Third party trademarks may appear on this Site/ Application and all rights therein are reserved to the registered owners of those trademarks. For use of any third party's intellectual property, You need to get permission directly from the owner of the intellectual property for any use.</p>

        <h5 className='mt-lg-4'> 16. TERM AND TERMINATION OF LICENSE AGREEMENT</h5>
        <p className='mt-lg-4'> Unless terminated explicitly, the agreement between Destene and You is perpetual in nature upon downloading the Application and for each Service booked through the Site.</p>

        <h5 className='mt-lg-4'> 17. ASSIGNMENT </h5>
        <p className='mt-lg-4'> You shall not assign Your rights under these User Terms without prior written approval of Destene. Destene can assign its rights under the User Terms to any affiliate.</p>

        <h5 className='mt-lg-4'> 18. APPLICABLE LAW AND DISPUTE RESOLUTION </h5>
        <p className='mt-lg-4'> These User Terms are subject to the laws of India. Any dispute, claim or controversy arising out of or relating to these User Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Site, the Service or the Application (collectively, “Disputes”) the parties shall attempt to settle the same amicably, through negotiation and consultation at such offices of Destene as Destene may designate. In the event the dispute is not resolved internally between after at least 30 (thirty) days of negotiation, in good faith, the same shall be subject to binding and final arbitration in accordance with the Arbitration and Conciliation Act, 1996 as amended from time to time or in case the Arbitration and Conciliation Act, 1996 is no longer in force, as per any law relating to arbitration in force at the time of such reference. The reference shall be made to a sole arbitrator mutually appointed by Destene and You. The place of the arbitration shall be Bengaluru, Karnataka, unless otherwise mutually agreed by Destene and You in writing. Subject to the above, any Dispute will be subject to the exclusive jurisdiction of courts in Bangalore, India.</p>


      </div>
    </>
  )
}

export default TermConditions